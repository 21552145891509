@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 1rem));
    }
}

.scroll {
    animation: scroll 50s linear infinite;
}

.reverse {
    animation-direction: reverse;
}

.box-container:hover {
    /* background: linear-gradient(
        111deg,
        rgba(221, 221, 221, 0.8) 17.46%,
        rgba(221, 221, 221, 0.1) 83.36%
    ); */
    backdrop-filter: blur(25px);
    border-top-left-radius: 27px;
    border-bottom-right-radius: 27px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: linear-gradient(to right, #231829, #1c2032);
    background-color: #1c2032;
    margin: 0;
    overscroll-behavior: none;
    font-family: 'K2D', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.menu-open {
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

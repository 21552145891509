.App {
    min-height: 100vh;
    max-width: 100vw;
    color: var(--white);
    overflow: hidden;
    scroll-behavior: smooth;
    /* overflow-y: hidden; */
    color: white;
    flex-direction: column;
}

.content-container {
    @apply pt-[5rem] md:pt-[6rem] 2xl:pt-[7rem];
}

.gradient-blur-light {
    background: linear-gradient(
        111deg,
        rgba(221, 221, 221, 0.8) 17.46%,
        rgba(221, 221, 221, 0.1) 83.36%
    );
    backdrop-filter: blur(25px);
    border: 1px solid rgba(221, 221, 221, 0.8);
}
.gradient-blur-orange {
    background: linear-gradient(153deg, #ff8e6f 7.73%, #ff6 91.16%);
    backdrop-filter: blur(25px);
    /* border: 1px solid rgba(221, 221, 221, 0.8); */
}

.app-subtitle {
    @apply md:text-4xl text-xl;
    color: #fff;
    font-weight: 700;
}

.gradient-text {
    background: linear-gradient(
        180deg,
        rgba(221, 221, 221, 0.9) 17.46%,
        rgba(221, 221, 221, 0.3) 83.36%
    );
    color: transparent;
    display: inline-block;
    background-clip: text;
}

.app-px {
    @apply px-6 xs:px-6 sm:px-10 md:px-16 lg:px-24;
}

.app-my {
    @apply my-6 xs:my-6 sm:my-10 md:my-16 lg:my-24;
}

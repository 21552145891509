.primary {
    color: #0b0b0b;
    background: linear-gradient(90deg, #ff8e6f 0.03%, #ff6 99.96%);
}

.secondary {
    color: #ddd;
    border: 1px solid #f77e0e;
    background: linear-gradient(180deg, #211b2c 20.65%, #f77e0e 418.48%);
}

.white {
    color: #020213;
    background: linear-gradient(
        92deg,
        rgba(221, 221, 221, 0.8) 26.59%,
        rgba(221, 221, 221, 0.1) 81.79%
    ) !important;

    width: 100% !important;
    border-radius: 18px;
}

.container {
    border-top-left-radius: 29px;
    border-top-right-radius: 29px;
    border-top: 1px solid rgba(221, 221, 221, 0.8);
    background: linear-gradient(
        111deg,
        rgba(221, 221, 221, 0.8) 17.46%,
        rgba(221, 221, 221, 0.1) 83.36%
    );
    backdrop-filter: blur(25px);
}

.animated-glow {
    -webkit-animation: glow 10s ease-in-out infinite alternate;
    -moz-animation: glow 10s ease-in-out infinite alternate;
    animation: glow 10s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 3px #fff, 0 0 6px #d7fdfd30, 0 0 9px #d7fdfd30,
            0 0 12px #d7fdfd30, 0 0 15px #d7fdfd30, 0 0 18px #d7fdfd30,
            0 0 21px #d7fdfd30;
    }
    to {
        text-shadow: 0 0 3px #fff, 0 0 6px #ffff6630, 0 0 9px #ffff6630,
            0 0 12px #ffff6630, 0 0 15px #ffff6630, 0 0 18px #ffff6630,
            0 0 21px #ffff6630;
    }
}

.animated-glow-div {
    -webkit-animation: glow-div 1s ease-in-out infinite alternate;
    -moz-animation: glow-div 1s ease-in-out infinite alternate;
    animation: glow-div 1s ease-in-out infinite alternate;
}

@keyframes glow-div {
    from {
        box-shadow: 0 0 2px #d7fdfd10, 0 0 4px #d7fdfd10, 0 0 6px #d7fdfd10,
            0 0 8px #d7fdfd10, 0 0 10px #d7fdfd10, 0 0 12px #d7fdfd10,
            0 0 14px #d7fdfd10;
    }
    to {
        box-shadow: 0 0 2px #ffff6630, 0 0 4px #ffff6630, 0 0 6px #ffff6630,
            0 0 8px #ffff6630, 0 0 10px #ffff6630, 0 0 12px #ffff6630,
            0 0 14px #ffff6630;
    }
}

.rotate {
    -webkit-animation: rotate 25s normal linear infinite;
    animation: rotate 25s normal linear infinite;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    25% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
        -webkit-transform: rotate3d(0, 0, 1, 180deg);
        transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
        -webkit-transform: rotate3d(0, 0, 1, 270deg);
        transform: rotate3d(0, 0, 1, 270deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

.reverse-rotate {
    -webkit-animation: reverse-rotate 25s normal linear infinite;
    animation: reverse-rotate 25s normal linear infinite;
}

@keyframes reverse-rotate {
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    75% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
        -webkit-transform: rotate3d(0, 0, 1, 180deg);
        transform: rotate3d(0, 0, 1, 180deg);
    }
    25% {
        -webkit-transform: rotate3d(0, 0, 1, 270deg);
        transform: rotate3d(0, 0, 1, 270deg);
    }
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

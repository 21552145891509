.form-input {
    margin: 24px 0;
}

.form-input input:focus,
.form-input textarea:focus {
    border: 2px solid white;
    outline: white;
}

.form-input input,
.form-input textarea {
    width: 100%;
    min-height: 65px;
    background-color: transparent;
    border: 1px solid rgba(221, 221, 221, 0.8);
    padding: 10px 0px;
    font-size: 20px;
    border-radius: 18px;
    padding: 0 24px;
}

.form-input input::placeholder,
.form-input textarea::placeholder {
    color: rgba(221, 221, 221, 0.75);
    font-weight: 400;
    font-size: 20px;
}

.form-input textarea {
    display: flex;
    /* flex: 1; */
    height: 200px;
}

@media screen and (max-width: 420px) {
    .form-input input,
    .form-input textarea {
        min-height: 45px;
        font-size: 16px;
        border-radius: 8px;
        padding: 4px 12px;
    }

    .form-input input::placeholder,
    .form-input textarea::placeholder {
        font-size: 16px;
    }
    .form-input textarea {
        height: 120px;
    }
}

.modsoft-2 {
    color: #1c2032 !important;
    background: linear-gradient(111deg, #ff8e6f 7.73%, #ff6 91.16%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 8px transparent;
    /* box-shadow: none !important; */
    width: 40vw;
    text-wrap: wrap;
    word-break: break-all;
    align-self: flex-start;
    box-sizing: border-box;
}

@media screen and (max-width: 1660px) {
    .modsoft-2 {
        width: 50vw !important;
    }
}

/* .modsoft-2:before {
    content: 'D\A';
    white-space: pre;
} */

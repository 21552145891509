.bg-image-container {
    background-size: cover;
    background-position-y: 0%;
    background-repeat: repeat;
    transition: background-position 0.3s ease;
    z-index: 1;
    animation: scroll 100s linear infinite;
}

@keyframes scroll {
    0% {
        background-position-y: 10%;
    }
    50% {
        background-position-y: 60%;
    }
    100% {
        background-position-y: 10%;
    }
}

/* Marquee.css */

@keyframes marqueeAnimation {
    0% {
        transform: translateX(-98%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes marqueeAnimation2 {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-98%);
    }
}

.marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marqueeAnimation 100s linear infinite; /* Adjust the duration and timing function */
}

.marquee-content-2 {
    display: inline-block;
    white-space: nowrap;
    animation: marqueeAnimation2 100s linear infinite; /* Adjust the duration and timing function */
}

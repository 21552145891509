.border-img {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wrapper-container {
    border-radius: 5px;
    position: relative;
    background-color: #1e1e30;
    width: 100%;
}

.form-input input,
.form-input textarea {
    width: 100%;
    min-height: 40px;
    background-color: transparent;
    border-bottom: 2px solid #fff;
    padding: 10px 0px;
}

.form-input input::placeholder,
.form-input textarea::placeholder {
    color: rgba(221, 221, 221, 0.25);
    font-weight: 400;
    /* font-size: 15px; */
}

.form-input textarea {
    display: flex;
    flex: 1;
    height: max-content;
}

.icon-container {
    /* background-color: red; */
    background: linear-gradient(
        0deg,
        rgba(221, 221, 221, 0.1) 10%,
        rgba(221, 221, 221, 0.8) 50%,
        rgba(221, 221, 221, 0.1) 90%
    );
}

@media screen and (max-width: 420px) {
    .icon-container {
        background: linear-gradient(
            90deg,
            rgba(221, 221, 221, 0.1) 10%,
            rgba(221, 221, 221, 0.8) 50%,
            rgba(221, 221, 221, 0.1) 90%
        );
    }
}
